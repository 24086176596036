import React from 'react';

import PropTypes from 'prop-types';

import SmileOne from '../images/smile-one.svg';
import ButtonLink from './ButtonLink';

const StartPage = ({
	description,
	titleFirst,
	titleStrong,
	titleSecond,
	alt,
	src,
	url,
	text,
}) => {
	return (
		<div className='relative isolate z-10'>
			<div
				className='absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80'
				aria-hidden='true'
			>
				<div
					className='relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#39b54a] to-[#0b9444] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]'
					style={{
						clipPath:
							'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
					}}
				/>
			</div>
			<div className='overflow-x-hidden'>
				<div className='mx-auto 2xl:container px-5 pb-16 pt-8 sm:pb-16 sm:pt-18 lg:pb-14 lg:pt-24 flex flex-col lg:flex-row gap-y-9 lg:gap-y-0'>
					<div className='text-left w-full lg:w-1/2 pr-5'>
						<h1 className='text-3xl lg:text-4xl tracking-tight text-gray-900 leading-normal lg:leading-relaxed'>
							<span>{titleFirst}</span>
							<br />
							<strong className='text-green-600'>{titleStrong}</strong>
							<br />
							<span>{titleSecond}.</span>
						</h1>
						<div
							className='mt-6 text-md leading-8 text-indigo-500'
							dangerouslySetInnerHTML={{ __html: description }}
						/>
						<ButtonLink url={url} text={text} />
					</div>
					<div className='lg:w-1/2 pl-0 lg:pl-5 relative flex items-center justify-start lg:justify-end'>
						<img
							src={SmileOne}
							alt='Osmijeh'
							className='max-w-max absolute left-0 -top-24 -z-10 hidden lg:inline-block'
						/>
						<img src={src} alt={alt} className='rounded-xl' />
					</div>
				</div>
			</div>
			<div
				className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
				aria-hidden='true'
			>
				<div
					className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#39b54a] to-[#0b9444] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
					style={{
						clipPath:
							'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
					}}
				/>
			</div>
		</div>
	);
};

StartPage.propTypes = {
	description: PropTypes.string,
	titleFirst: PropTypes.string,
	titleStrong: PropTypes.string,
	titleSecond: PropTypes.string,
	src: PropTypes.string,
	alt: PropTypes.string,
	url: PropTypes.string,
	text: PropTypes.string,
};
export default StartPage;
