import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';

const Counter = ({ start, end, duration, bcg, text, color, suffix }) => {
	return (
		<div
			className={`rounded-full w-[250px] h-[250px] flex flex-col gap-y-3 justify-center items-center text-5xl ${bcg} ${color} font-bold`}
		>
			<CountUp
				start={start}
				end={end}
				duration={duration}
				suffix={suffix}
				enableScrollSpy
			>
				{({ countUpRef }) => <span ref={countUpRef} />}
			</CountUp>
			<div className='line w-[50%] h-[1px] bg-white-600'></div>
			<span className='text-lg text-white-200 font-thin'>{text}</span>
		</div>
	);
};
Counter.propTypes = {
	start: PropTypes.number,
	end: PropTypes.number,
	duration: PropTypes.number,
	bcg: PropTypes.string,
	text: PropTypes.string,
	color: PropTypes.string,
	suffix: PropTypes.string,
};

export default Counter;
