import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import PropTypes from 'prop-types';

const Phase = ({
	number,
	title,
	textone,
	src,
	alt,
	speedOne,
	speedTwo,
	order,
}) => {
	return (
		<Parallax translateX={[speedOne, speedTwo]}>
			<div className='flex flex-wrap py-12'>
				<div className={`w-full lg:w-1/2 px-5 ${order} my-5 lg:mb-0`}>
					<img src={src} alt={alt} className='w-full' />
				</div>
				<div className={`w-full lg:w-1/2 px-5`}>
					<div className='text-4xl lg:text-8xl text-green-400'>{number}</div>
					<h3 className='text-2xl my-4'>{title}</h3>
					<p>{textone}</p>
				</div>
			</div>
		</Parallax>
	);
};
Phase.propTypes = {
	number: PropTypes.string,
	title: PropTypes.string,
	textone: PropTypes.string,
	texttwo: PropTypes.string,
	src: PropTypes.string,
	alt: PropTypes.string,
	speedOne: PropTypes.string,
	speedTwo: PropTypes.string,
	order: PropTypes.string,
};

export default Phase;
