import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
	return (
		<>
			<Helmet>
				<title>404 Not Found - Geek Solutions</title>
				<meta name='prerender-status-code' content='404' />
			</Helmet>
			<main className='grid place-items-center py-24 px-6 pt-64 lg:px-8'>
				<div className='text-center'>
					<p className='text-2xl font-semibold text-red-600'>404</p>
					<h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
						Stranica nije pronađena
					</h1>
					<p className='mt-6 text-base leading-7 text-gray-700'>
						Žao nam je, nismo uspjeli pronaći stranicu koju tražite.
					</p>
					<div className='mt-10 flex items-center justify-center gap-x-6'>
						<Link
							to='/'
							className='rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-indigo-500 hover:text-white-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						>
							Naslovna
						</Link>
						<Link
							to='/kontakt'
							className='text-sm font-semibold text-gray- hover:text-green-600'
						>
							Kontaktiraj podršku <span aria-hidden='true'>&rarr;</span>
						</Link>
					</div>
				</div>
			</main>
		</>
	);
};

export default NotFound;
