import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Bcg from '../images/bg-1.svg';

const ButtonLink = ({ url, text, isPdf }) => {
	return (
		<Link
			to={url}
			className='w-[220px] h-[70px] flex relative justify-center items-center mt-5 text-white-100 transition-colors hover:text-blue-700'
			target={`${isPdf ? '_blank' : '_self'}`}
		>
			<span className='mt-2 relative z-10'>{text}</span>
			<img
				src={Bcg}
				alt='Pozadina'
				className='absolute top-0 left-0 w-[220px] h-[70px]'
			/>
		</Link>
	);
};
ButtonLink.propTypes = {
	url: PropTypes.string,
	text: PropTypes.string,
};
export default ButtonLink;
