import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { FaTimes } from 'react-icons/fa';

import Bcg from '../images/bg-1.svg';

const RegisterSchema = Yup.object().shape({
	name: Yup.string().required('Obavezno polje'),
	email: Yup.string().email('Email nije validan').required('Obavezno polje'),
	phone: Yup.string().required('Obavezno polje'),
});
const CourseRegistrationForm = ({
	groupValue,
	courseValue,
	setIsActive,
	setData,
}) => {
	return (
		<div className='fixed max-w-[560px]  min-w-[320px] p-6 pt-16 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white-600 rounded-lg z-30'>
			<div className='close absolute top-3 right-3'>
				<button
					type='button'
					onClick={() => setIsActive(false)}
					className='border w-[38px] h-[38px] border-white-300 rounded-full hover:bg-green-500 hover:text-white-200 transition flex justify-center items-center'
				>
					<FaTimes />
				</button>
			</div>
			<Formik
				initialValues={{
					name: '',
					email: '',
					phone: '',
					message: '',
					course: courseValue,
					groupOrIndividual: groupValue,
				}}
				validationSchema={RegisterSchema}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						const formdata = new FormData();
						formdata.append('name', values.name);
						formdata.append('email', values.email);
						formdata.append('phone', values.phone);
						formdata.append('message', values.message);
						formdata.append('course', values.course);
						formdata.append('groupOrIndividual', values.groupOrIndividual);

						const res = await axios.post(
							'/api/applicaton_for_course.php',
							formdata
						);
						const data = await res.data;
						setData(data);
						setIsActive(false);
						setSubmitting(false);
						setTimeout(() => {
							setData('');
						}, [3000]);
					} catch (err) {
						console.log(err);
					}
				}}
			>
				{({ values }) => (
					<Form>
						<Field
							name='name'
							type='text'
							value={values.name}
							placeholder='Ime i prezime*'
							className='w-full bg-transparent rounded-md mb-2 border-white-300'
						/>
						<ErrorMessage
							name='name'
							render={(msg) => <div className='text-red-600'>{msg}</div>}
						/>
						<Field
							name='email'
							type='email'
							placeholder='Email*'
							value={values.email}
							className='w-full bg-transparent rounded-md mb-2 border-white-300'
						/>
						<ErrorMessage
							name='email'
							render={(msg) => <div className='text-red-600'>{msg}</div>}
						/>
						<Field
							name='phone'
							type='tel'
							placeholder='Telefon*'
							value={values.phone}
							className='w-full bg-transparent rounded-md mb-2 border-white-300'
						/>
						<ErrorMessage
							name='phone'
							render={(msg) => <div className='text-red-600'>{msg}</div>}
						/>
						<Field
							name='message'
							as='textarea'
							value={values.message}
							placeholder='Poruka'
							className='w-full bg-transparent rounded-md mb-2 border-white-300 h-24'
						/>
						<Field
							name='course'
							type='hidden'
							value={values.course}
							className='w-full'
						/>
						<Field
							name='groupOrIndividual'
							type='hidden'
							value={values.groupOrIndividual}
						/>
						<button
							type='submit'
							className='w-[220px] h-[70px] flex relative justify-center items-center mt-5 text-white-100 transition-colors hover:text-blue-700'
						>
							<img
								src={Bcg}
								alt='Pozadina'
								className='absolute top-0 left-0 w-[220px] h-[70px]'
							/>
							<span className='mt-2 relative z-10'>Pošaljite upit</span>
						</button>
					</Form>
				)}
			</Formik>
		</div>
	);
};
CourseRegistrationForm.propTypes = {
	groupValue: PropTypes.string,
	courseValue: PropTypes.string,
	setIsActive: PropTypes.func,
};
export default CourseRegistrationForm;
