import React from 'react';
import Smile from '../images/smile-four.svg';
import Dev from '../images/developer.webp';

const ContactHeader = () => {
	return (
		<div className='relative h-auto lg:h-80 overflow-hidden'>
			<div className='absolute hidden lg:inline-block -rotate-[7deg] w-[380%] h-screen bg-green-200 left-[16%] bottom-[30px] 2xl:bottom-[40px] -translate-x-1/2 origin-bottom'></div>
			<div className='container mx-auto px-5 relative z-10 flex flex-col lg:flex-row justify-between items-center h-full gap-y-5'>
				<h1 className='text-3xl lg:text-4xl'>Kontaktiraj nas</h1>
				<div className='flex-1 w-full lg:w-auto relative flex justify-center lg:justify-end lg:pr-36'>
					<img
						src={Smile}
						alt='Osmijeh'
						className='absolute right-0 top-0 w-[506px]'
					/>
					<img src={Dev} alt='Developer' className='w-[220px] relative z-10' />
				</div>
			</div>
		</div>
	);
};

export default ContactHeader;
