import React from 'react';
import WhyUsCard from './WhyUsCard';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { FaChartBar } from 'react-icons/fa';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { FaHeadset } from 'react-icons/fa';

const data = [
	{
		id: 1,
		title: 'Iskusni predavači',
		text: ' Predavači su profesionalci sa višegodišnjim iskustvom iz svojih oblasti.',
		child: FaChalkboardTeacher,
	},
	{
		id: 2,
		title: 'Praktični primjeri ',
		text: 'Rad na praktičnim primjerima uz prateću literaturu.',
		child: FaChartBar,
	},
	{
		id: 3,
		title: 'Plaćanje na rate ',
		text: 'Mogućnost plaćanja na rate. Prva dva časa besplatna.',
		child: FaHandHoldingUsd,
	},
	{
		id: 4,
		title: 'Online - Uživo',
		text: 'Komunikacija sa predavačima <strong>uživo</strong>, podrška i nakon završetka kursa.',
		child: FaHeadset,
	},
];

const WhyUs = () => {
	return (
		<div className='container mx-auto px-5 pb-8 text-center'>
			<h2 className='text-3xl lg:text-4xl mb-5'>
				Zašto izabrati <strong>naše kurseve</strong>
			</h2>
			<p className='max-w-4xl mx-auto mb-8'>
				<strong className='text-green-500'>
					Škola računara i programiranja
				</strong>{' '}
				"Geek Solutions" godinama unazad nudi veliki broj online/live kurseva.
				Bilo da ste u zemlji ili inostranstvu polaznici kurseve prate u realnom
				vremenu, ne gubite vrijeme na odlaske do učionice kada je učionica tu u
				Vašem domu. Putem direktne komunikacije sa predavačem komunicirate
				cijelo vrijeme trajanja časa. Sve što Vam je potrebno je da posjedujete
				računar i dobru internet konekciju.
			</p>
			<div className='flex flex-wrap gap-x-5 gap-y-5'>
				{data.map((item) => (
					<WhyUsCard
						key={item.id}
						title={item.title}
						text={item.text}
						children={<item.child />}
					/>
				))}
			</div>
		</div>
	);
};

export default WhyUs;
