import React, { createContext, useReducer, useContext } from 'react';

const CoursesContext = createContext();

const initialCourses = {
	courses: null,
	course: null,
	category: null,
	teachers: null,
	teacher: null,
	loading: true,
	error: '',
};

function coursesReducer(state, action) {
	switch (action.type) {
		case 'GET_COURSES':
			return {
				...state,
				courses: action.courses,
				loading: false,
			};
		case 'GET_COURSE':
			const courseItem = state.courses?.find(
				(course) => course.course_slug === action.id
			);
			return {
				...state,
				course: courseItem,
			};
		case 'GET_CATEGORY':
			return {
				...state,
				category: action.category,
			};
		case 'GET_TEACHERS':
			return {
				...state,
				teachers: action.teachers,
			};
		case 'GET_TEACHER':
			const teacherItem = state.teachers?.find(
				(teacher) => teacher.id === state.course.treiner_id
			);
			return {
				...state,
				teacher: teacherItem,
			};
		case 'GET_ERROR':
			return {
				...state,
				error: action.error,
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
}

export async function getTeachers() {}

export function useCoursesContext() {
	const context = useContext(CoursesContext);
	if (!context) {
		throw new Error('useCorsesContext must be used within the CoursesProvider');
	}
	return context;
}

function CoursesProvider({ children }) {
	const [state, dispatch] = useReducer(coursesReducer, initialCourses);
	const value = [state, dispatch];
	return (
		<CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>
	);
}

export default CoursesProvider;
