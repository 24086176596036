import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

import CoursesProvider from './utils/CoursesProvider';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
	const root = hydrateRoot(
		rootElement,
		<React.StrictMode>
			<HelmetProvider>
				<CoursesProvider>
					<App />
				</CoursesProvider>
			</HelmetProvider>
		</React.StrictMode>
	);
	console.log(root);
} else {
	const root = createRoot(document.getElementById('root'));

	root.render(
		<React.StrictMode>
			<HelmetProvider>
				<CoursesProvider>
					<App />
				</CoursesProvider>
			</HelmetProvider>
		</React.StrictMode>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
