import React, { useMemo, Suspense, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ParallaxBanner } from 'react-scroll-parallax';
import CategoryCard from '../components/CategoryCard';

import Courses from '../data/courses.json';

import Geek from '../images/icons/geek-solution-nerd-pokemon-go-logo-geek-logo-text-computer-logo.png';

import City from '../images/city-bg.webp';
import CityMob from '../images/city-mob-bg.webp';

import Loader from '../images/green-loader.gif';

const Category = () => {
	const { kategorija } = useParams();
	const [categories, setCategories] = useState();
	const [loading, setLoading] = useState(true);
	const [city, setCity] = useState(CityMob);
	const addBcgImage = () => {
		if (window.innerWidth < 760) {
			setCity(CityMob);
		} else {
			setCity(City);
		}
	};
	useEffect(() => {
		addBcgImage();
		window.addEventListener('resize', addBcgImage);
		return () => {
			window.removeEventListener('resize', addBcgImage);
		};
	}, []);
	useEffect(() => {
		const categories = Courses.filter((item) => item.category === kategorija);
		setCategories(categories);
		setLoading(false);
	}, [kategorija, setCategories]);
	const categoriesElms = useMemo(
		() =>
			categories &&
			categories?.map((item) => (
				<CategoryCard
					key={item.id}
					url={item.course_slug}
					courseName={item.title}
					hours={item.houers_group}
					price={item.price_group}
					bcgColor={item.bcg_color}
				/>
			)),
		[categories]
	);
	const title = useMemo(
		() => (categories ? categories[0]?.category_title : null),
		[categories]
	);
	if (loading) {
		return (
			<div className='flex justify-center items-center'>
				<img src={Loader} alt='Green Loader' className='max-w-[250px]' />
			</div>
		);
	}
	return (
		<>
			<Helmet>
				<title>{title} - Geek Solutions</title>
				<link
					rel='canonical'
					href={`https://www.informaticki-kursevi.ba/kursevi/${kategorija}`}
				/>
				<link
					rel='alternate'
					href={`https://www.informaticki-kursevi.ba/kursevi/${kategorija}`}
					hreflang='sr-BA'
				/>
				<meta
					name='description'
					content='Započnite svoju IT karijeru danas! Otkrijte širok spektar kvalitetnih IT kurseva u našim kategorijama. Bez obzira na vaš nivo znanja, nudimo vam kurseve koja će vas osnažiti u svijetu tehnologije. Stručni i predani predavači.'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content={`${title} - Geek Solutions`} />
				<meta
					property='og:description'
					content='Započnite svoju IT karijeru danas! Otkrijte širok spektar kvalitetnih IT kurseva u našim kategorijama. Bez obzira na vaš nivo znanja, nudimo vam kurseve koja će vas osnažiti u svijetu tehnologije. Stručni i predani predavači.'
				/>
				<meta property='og:image' content={Geek} />
			</Helmet>
			<div className='container mx-auto px-5 py-5'>
				<nav className='w-full rounded-md'>
					<ol className='list-reset flex'>
						<li>
							<Link
								to='/kursevi'
								className='text-sm text-blue-600 transition duration-150 ease-in-out hover:text-green-600 focus:text-primary-600 active:text-primary-700 uppercase font-bold'
							>
								Kursevi
							</Link>
						</li>
						<li>
							<span className='text-sm mx-1 text-neutral-500 dark:text-neutral-400'>
								/
							</span>
						</li>
						<li className='text-sm text-neutral-500 dark:text-neutral-400 font-bold'>
							{kategorija.toUpperCase()}
						</li>
					</ol>
				</nav>
			</div>
			<div id='courses' className='bg-indigo-400'>
				<ParallaxBanner layers={[{ image: city, speed: -20 }]}>
					<div className='2xl:container mx-auto px-5 2xl:px-0 pb-16 pt-8'>
						<h1 className='text-center text-3xl lg:text-4xl text-white-500 font-bold my-4 relative z-10'>
							<span className='uppercase'>
								<Suspense fallback={null}>{title}</Suspense>
							</span>{' '}
							kursevi
						</h1>
						<div className='flex flex-wrap'>
							<Suspense fallback={null}>{categoriesElms}</Suspense>
						</div>
					</div>
				</ParallaxBanner>
			</div>
		</>
	);
};

export default Category;
