import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import StartPage from '../components/StartPage';

import Dev from '../images/developer.webp';
import HomePases from '../components/HomePases';
import Counter from '../components/Counter';
import Faq from '../components/Faq';
import Geek from '../images/icons/geek-solution-nerd-pokemon-go-logo-geek-logo-text-computer-logo.png';
import WhyUs from '../components/WhyUs';

const faq = [
	{
		id: 1,
		title: 'Da li se kursevi održavaju online ili u učionici?',
		answer:
			'<p>Kurseve možete pohađati na jedan od ponuđenih načina izvođenja nastave:</p><h3 class="font-semibold my-2">Nastava u učionici</h3><p>Nastava se odvija u našim prostorijama. Polaznici imaju obezbjeđujemo opremu za rad iako mogu da koriste i svoje lične računare</p><h3 class="font-semibold my-2">Online nastava</h3><p>Nastava se odvija putem Zoom platforme i zaista se trudimo da u najvećoj mogućoj mjeri izgleda kao atmosfera iz učionice. Nastava je interaktivna, polaznici mogu međusobno da komuniciraju, postavljaju pitanja predavačima i dijele ekran.</p><p class="mt-2"><em>Napomena: Nastava u učionici nije moguća na svim kursevima koje imamo u ponudi.</em></p>',
		isActive: true,
	},
	{
		id: 2,
		title: 'Šta da radim ukoliko ne mogu prisustvovati času?',
		answer:
			'Za časove koje propustite dobićete video snimak sa propuštenog predavanja, a možete u dogovoru s predavačem organizovati i nadoknadu propuštenog časa.',
		isActive: false,
	},
	{
		id: 3,
		title: 'Kako da znam da je ovaj kurs dobar odabir za mene?',
		answer:
			' Pregledajte informacije o <a class="text-green-600 hover:text-green-800 font-semibold" href="/kursevi">kursevima</a> koji Vas zanimaju na našoj internet stranici i budite slobodni da nas kontaktirate.',
		isActive: false,
	},
	{
		id: 4,
		title: 'Šta dobijam nakon završenog kursa?',
		answer:
			'<p>Nakon završetka kursa, imate mjesec dana da radite na završnom radu koji pregleda vaš predavač.</p><p>Taj rad, kao i svi drugi tokom pohađanja kursa, ostaju dio vašeg portfolija i mogu vam pomoći pri apliciranju za posao.</p><p>Takođe, dobijate i sertifikat o stečenom znanju.</p>',
		isActive: false,
	},
	{
		id: 5,
		title: 'Šta mi je potrebno od opreme za pohađanje kursa?',
		answer:
			'<p>Kada je riječ o nastavi u učionici ukoliko nemate sopstveni laptop, mi ćemo vam obezbijediti isti.</p><p>Za online nastavu vam je potreban računar performansi koje su neophodne za aplikacije koje koristimo tokom kursa i naravno instalirana Zoom aplikacija i internet konekcija.</p>',
		isActive: false,
	},
];

const Home = () => {
	const [faqState, setFaqState] = useState(faq);

	const faqButtonClickHandler = (index) => {
		const newArray = faqState.map((item, i) => {
			if (index === i + 1) {
				return { ...item, isActive: !item.isActive };
			} else {
				return { ...item, isActive: false };
			}
		});
		setFaqState(newArray);
	};

	return (
		<>
			<Helmet>
				<title>Geek Solutions</title>
				<link rel='canonical' href={`https://www.informaticki-kursevi.ba/`} />
				<link
					rel='alternate'
					href={`https://www.informaticki-kursevi.ba/`}
					hreflang='sr-BA'
				/>
				<meta
					name='description'
					content='Uplovite u svijet informatike i steknite nova znanja i vještine kroz naše kurseve računara iz svih oblasti informacionih tehnologija! Pohađajte kurseve - od početnika do eksperta. Naučite o web dizajnu, grafičkom dizajnu i primjeni informatike u poslovnom okruženju. Plaćanje na rate.Sertifikat.'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content='Geek Solutions' />
				<meta
					property='og:description'
					content='Uplovite u svijet informatike i steknite nova znanja i vještine kroz naše kurseve računara iz svih oblasti informacionih tehnologija! Pohađajte kurseve - od početnika do eksperta. Naučite o web dizajnu, grafičkom dizajnu i primjeni informatike u poslovnom okruženju. Plaćanje na rate.Sertifikat.'
				/>
				<meta property='og:image' content={Geek} />
			</Helmet>
			<div className='home'>
				<StartPage
					description='Mišljenja smo da se najbolje <strong>uči kroz praksu</strong>, te su svi naši kursevi bazirani na <strong>praktičnim primjerima</strong> koje su naši predavači kreirali na osnovu zahtjeva koji su nam kao svoje poslovne zadatke postavljali oni koji su slušali naše kurseve prije vas. Na osnovu tih njihovih primjera iz prakse vremenom su nastajali naši “školski” primjeri koje smo kasnije koristili za naše buduće polaznike. Tokom kursa <strong>predavači će konstantno pratiti vaš rad</strong>, dobijaćete domaće zadatke a i nakon kursa saradnja sa našim predavačima ne mora da se završi.'
					titleFirst='Vaša potraga za '
					titleStrong='INFORMATIČKIM KURSEVIMA'
					titleSecond=' se ovdje završava'
					src={Dev}
					alt='Developer'
					url='/kursevi'
					text='Vidi kurseve'
				/>
			</div>
			<WhyUs />
			<HomePases />
			<div className='container mx-auto px-5'>
				<h2 className='text-3xl lg:text-4xl text-center'>Naše brojke</h2>
				<div className='flex flex-wrap justify-center gap-y-4 sm:justify-between py-12 px-0 2xl:px-32'>
					<Counter
						start={0}
						end={3}
						duration={1}
						bcg='bg-green-500'
						text='GODINE RADA'
						color='text-white-200'
						suffix=''
					/>

					<Counter
						start={0}
						end={27}
						duration={2}
						bcg='bg-indigo-500'
						text='AKTIVNA KURSA'
						color='text-white-200'
						suffix=''
					/>
					<Counter
						start={0}
						end={350}
						duration={4}
						bcg='bg-green-600'
						text='POLAZNIKA'
						color='text-white-200'
						suffix='+'
					/>
					<Counter
						start={0}
						end={250}
						duration={3}
						bcg='bg-indigo-600'
						text='ODRŽANIH KURSEVA'
						color='text-white-200'
						suffix='+'
					/>
				</div>
			</div>
			<div className='container mx-auto px-5 pt-6 mb-12'>
				<div className='title mb-12 text-center'>
					<h2 className='text-3xl lg:text-4xl mb-8'>
						FAQ - Često postavljena pitanja
					</h2>
					{/*<p className='max-w-[680px] mx-auto'></p>*/}
				</div>
				{faqState.map((item) => (
					<Faq
						key={item.id}
						title={item.title}
						answer={item.answer}
						isActive={item.isActive}
						onclick={() => faqButtonClickHandler(item.id)}
					/>
				))}
			</div>
		</>
	);
};

export default Home;
