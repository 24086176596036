import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../images/logo-white.png';
import { Link } from 'react-router-dom';
import Mail from '../images/mail.svg';

const activeStyle = {
	color: '#39b54a',
};

const Footer = () => {
	return (
		<footer className='text-center bg-neutral-600 text-neutral-200 lg:text-left relative z-10'>
			<div className='container mx-auto px-5 py-10 text-center md:text-left'>
				<div className='grid-1 grid gap-8 md:grid-cols-2 xl:grid-cols-4'>
					<div className='flex flex-col justify-between items-center'>
						<Link to='/' className='my-auto'>
							<img src={Logo} alt='Footer Logo' className='h-32' />
						</Link>
						<h6 className='mb-4 mt-4 lg:mt-0 flex items-center justify-center font-semibold uppercase md:justify-start'>
							Geek SOLUTIONS
						</h6>
					</div>

					<div className=''>
						<h6 className='mb-4 flex justify-center font-semibold uppercase md:justify-start'>
							Popularni kursevi
						</h6>
						<p className='mb-4'>
							<NavLink
								to='kursevi/ms-office/osnovni-nivo'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								MS Ofiice osnovni nivo
							</NavLink>
						</p>
						<p className='mb-4'>
							<NavLink
								to='kursevi/ms-office/excel-osnovni-nivo'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Excel osnove
							</NavLink>
						</p>
						<p className='mb-4'>
							<NavLink
								to='kursevi/programiranje/java-osnove'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Java osnove
							</NavLink>
						</p>
						<p className='mb-4'>
							<NavLink
								to='/kursevi/web-design/html-css-sass-tailwind'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								HTML CSS SASS TAILWIND
							</NavLink>
						</p>
						{/*<p className='mb-4'>
							<NavLink
								to='/kursevi/programiranje/javascript'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								JavaScript
							</NavLink>
						</p>
						<p className='mb-4'>
							<NavLink
								to='/kursevi/programiranje/react'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								React
							</NavLink>
	</p>*/}
					</div>

					<div className=''>
						<h6 className='mb-4 flex justify-center font-semibold uppercase md:justify-start'>
							Kategorije
						</h6>
						<p className='mb-4'>
							<NavLink
								to='/kursevi/ms-office'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Ms Office
							</NavLink>
						</p>
						<p className='mb-4'>
							<NavLink
								to='/kursevi/web-design'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Web dizajn
							</NavLink>
						</p>

						<p className='mb-4'>
							<NavLink
								to='/kursevi/graficki-dizajn'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Graficki dizajn
							</NavLink>
						</p>
						<p className='mb-4'>
							<NavLink
								to='/kursevi/programiranje'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Programiranje
							</NavLink>
						</p>
						<p className='mb-4'>
							<NavLink
								to='/kursevi/cad-cam'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Cad Cam
							</NavLink>
						</p>
						<p>
							<NavLink
								to='/kursevi/kursevi-za-djecu'
								className='text-neutral-200 hover:text-green-500'
								style={({ isActive }) => (isActive ? activeStyle : null)}
							>
								Kursevi za najmlađe
							</NavLink>
						</p>
					</div>

					<div className='flex flex-col justify-between'>
						<div>
							<h6 className='mb-4 flex justify-center font-semibold uppercase md:justify-start'>
								Kontakt
							</h6>
							<p className='mb-4 flex items-steart justify-center md:justify-start'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='mr-3 h-5 w-5'
								>
									<path d='M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z' />
									<path d='M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z' />
								</svg>
								Bosna i Hercegovina
							</p>
							<p className='mb-4 flex items-center justify-center md:justify-start'>
								<img src={Mail} alt='mail' className='mr-3 h-5 w-5' />
								informatickionlinekursevi@gmail.com
							</p>
							<p className='mb-4 flex items-center justify-center md:justify-start'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='mr-3 h-5 w-5'
								>
									<path
										fillRule='evenodd'
										d='M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z'
										clipRule='evenodd'
									/>
								</svg>
								+387 66 660 150
							</p>
						</div>
						<div className='flex items-center justify-center md:justify-start'>
							<a
								href='https://www.facebook.com/profile.php?id=100088364689686'
								className='mr-6 text-white-200 hover:text-green-500'
								rel='noreferrer'
								target='_blank'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-4 w-4'
									fill='currentColor'
									viewBox='0 0 24 24'
								>
									<path d='M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z' />
								</svg>
							</a>

							<a
								href='https://www.instagram.com/it_kursevi_geek_solutions/'
								className='mr-6 text-white-200 hover:text-green-500'
								rel='noreferrer'
								target='_blank'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-4 w-4'
									fill='currentColor'
									viewBox='0 0 24 24'
								>
									<path d='M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z' />
								</svg>
							</a>
							<a
								href='https://www.linkedin.com/in/geek-solutions/'
								className='mr-6 text-white-200 hover:text-green-500'
								rel='noreferrer'
								target='_blank'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-4 w-4'
									fill='currentColor'
									viewBox='0 0 24 24'
								>
									<path d='M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z' />
								</svg>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className='p-6 text-center bg-neutral-700'>
				<span>© 2020 Geek Solutions | </span>
				<NavLink className='font-semibold text-neutral-400' to='/'>
					All rights reserved
				</NavLink>
			</div>
		</footer>
	);
};

export default Footer;
