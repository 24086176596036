import React from 'react';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom';

import { ParallaxProvider } from 'react-scroll-parallax';

import Layout from './Layout';
import Home from './pages/Home';
import NotFound from './pages/404';
import Contact from './pages/Contact';
import Courses from './pages/Courses';
import Category from './pages/Category';
import Course from './pages/Course';
import Certification from './pages/Certification';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/' element={<Layout />}>
			<Route index element={<Home />} />
			<Route path='kontakt' element={<Contact />} />
			<Route path='testiranje-i-sertifikacija' element={<Certification />} />
			<Route path='kursevi'>
				<Route index element={<Courses />} />
				<Route path=':kategorija'>
					<Route index element={<Category />} />
					<Route path=':id' element={<Course />} />
				</Route>
			</Route>
			<Route path='*' element={<NotFound />} />
		</Route>
	)
);

function App() {
	return (
		<div className='bg-white-500'>
			<ParallaxProvider>
				<RouterProvider router={router}></RouterProvider>
			</ParallaxProvider>
		</div>
	);
}

export default App;
