import React, { useEffect, useState } from 'react';
//import CoursesTop from '../components/CoursesTop';
import CourseCard from '../components/CourseCard';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet-async';

import MsOffice from '../images/icons/office.png';
import WebDesign from '../images/icons/web-design.png';
import Programing from '../images/icons/programing.png';
import ChildrenEducation from '../images/icons/abc-block.png';
import Adobe from '../images/icons/adobe.png';
import CadCam from '../images/icons/cad-cam.png';
import Geek from '../images/icons/geek-solution-nerd-pokemon-go-logo-geek-logo-text-computer-logo.png';

import City from '../images/city-bg.webp';
import CityMob from '../images/city-mob-bg.webp';

const coursesData = [
	{
		id: 1,
		courseGroup: 'MS Office',
		coursesNumber: '5',
		url: 'ms-office',
		bcgImage: MsOffice,
		bcgAlt: 'MS Office',
		isHover: false,
	},
	{
		id: 2,
		courseGroup: 'Web dizajn',
		coursesNumber: '1',
		url: 'web-design',
		bcgImage: WebDesign,
		bcgAlt: 'Web dizajn',
		isHover: false,
	},
	{
		id: 3,
		courseGroup: 'Grafički dizajn',
		coursesNumber: '3',
		url: 'graficki-dizajn',
		bcgImage: Adobe,
		bcgAlt: 'Grafički dizajn',
		isHover: false,
	},
	{
		id: 4,
		courseGroup: 'Programiranje',
		coursesNumber: '4',
		url: 'programiranje',
		bcgImage: Programing,
		bcgAlt: 'Programiranje',
		isHover: false,
	},
	{
		id: 5,
		courseGroup: 'Cad Cam',
		coursesNumber: '4',
		url: 'cad-cam',
		bcgImage: CadCam,
		bcgAlt: 'Cad Cam',
		isHover: false,
	},
	{
		id: 6,
		courseGroup: 'Kursevi za djecu',
		coursesNumber: '3',
		url: 'kursevi-za-djecu',
		bcgImage: ChildrenEducation,
		bcgAlt: 'Kursevi za najmlađe',
		isHover: false,
	},
];

const Courses = () => {
	const [courses, setCourses] = useState(coursesData);
	const [city, setCity] = useState(CityMob);

	const updateState = (index, value) => {
		const newArray = courses.map((item, i) => {
			if (index === i + 1) {
				return { ...item, isHover: value };
			} else {
				return item;
			}
		});
		setCourses(newArray);
	};
	const addBcgImage = () => {
		if (window.innerWidth < 760) {
			setCity(CityMob);
		} else {
			setCity(City);
		}
	};

	useEffect(() => {
		addBcgImage();
		window.addEventListener('resize', addBcgImage);
		return () => {
			window.removeEventListener('resize', addBcgImage);
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>Kursevi - Geek Solutions</title>
				<link
					rel='canonical'
					href={`https://www.informaticki-kursevi.ba/kursevi/`}
				/>
				<link
					rel='alternate'
					href={`https://www.informaticki-kursevi.ba/kursevi/`}
					hreflang='sr-BA'
				/>
				<meta
					name='description'
					content='Raznovrsni kursevi: web design, grafički dizajn, programiranje, MS Office i CAD CAM. Prilika za online ili uživo pohađanje kurseva. Plaćanje na rate. Sertifikat. Pogledajte našu ponudu i izaberite idealan kurs za sebe ili vaše dijete.'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content='Kursevi - Geek Solutions' />
				<meta
					property='og:description'
					content='Raznovrsni kursevi: web design, grafički dizajn, programiranje, MS Office i CAD CAM. Prilika za online ili uživo pohađanje kurseva. Plaćanje na rate. Sertifikat. Pogledajte našu ponudu i izaberite idealan kurs za sebe ili vaše dijete.'
				/>
				<meta property='og:image' content={Geek} />
			</Helmet>
			{/*<CoursesTop />*/}
			<div id='courses' className='bg-indigo-400'>
				<ParallaxBanner layers={[{ image: city, speed: -20 }]}>
					<div className='container mx-auto px-5 2xl:px-0 text-center text-white-200 py-16'>
						<h1 className='text-3xl text-white-200 tracking-wider relative z-10 font-bold lg:text-4xl mb-5'>
							Informatički kursevi
						</h1>
						<p className='max-w-[760px] mx-auto relative z-10'>
							Upustite se u svijet informatike i unaprijedite neophodne vještine
							za uspješnu karijeru doba u kome živimo, te budite ispred svoje
							konkurencije. Usvojite nova znanja kroz naše{' '}
							<strong className='text-orange-500'>
								kurseve za totalne početnike, kurseve programiranja, web i
								grafičkog dizajna, CAD CAM
							</strong>{' '}
							i kurseve{' '}
							<strong className='text-orange-500'>digitalnog marketinga</strong>
							. Odabirom bilo kojeg kursa iz oblasti koja vas zanima dobićete
							mentora koji će sa velikom posvećenošću pratiti svakog polaznika i
							njegov napredak.
						</p>
					</div>
					<div className='2xl:container mx-auto px-5 2xl:px-0 pb-16 flex flex-wrap'>
						{courses.map((course) => (
							<CourseCard
								key={course.id}
								courseGroup={course.courseGroup}
								coursesNumber={course.coursesNumber}
								url={course.url}
								bcgImage={course.bcgImage}
								bcgAlt={course.bcgAlt}
								isHover={course.isHover}
								onmouseenter={() => updateState(course.id, true)}
								onmouseleave={() => updateState(course.id, false)}
							/>
						))}
					</div>
				</ParallaxBanner>
			</div>
		</>
	);
};

export default Courses;
