import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Decoration from '../images/decoration-1.svg';

const CourseCard = ({ courseGroup, coursesNumber, url, bcgImage, bcgAlt }) => {
	return (
		<article className='w-full md:w-6/12 xl:w-4/12 px-0 py-4 md:px-4'>
			<Link
				to={url}
				className='group relative flex min-h-[200px] p-5 overflow-hidden bg-white-500 rounded-lg'
			>
				<img
					src={bcgImage}
					alt={bcgAlt}
					className='absolute right-3 lg:right-8  top-1/2 -translate-y-1/2 w-[150px]'
				/>
				<div className='flex justify-between relative z-10 min-h-[160px] w-full'>
					<div className='flex flex-col items-start justify-between'>
						<h3 className='card-title heading-h4 text-xl lg:text-2xl font-bold'>
							{courseGroup}
						</h3>
						<span className='relative px-5 pb-3 text-indigo-300 group-hover:text-green-500'>
							Svi kursevi
							<img
								src={Decoration}
								alt='Dekoracija'
								className='absolute transition-all left-0 w-full bottom-2 animate-bounce hidden group-hover:inline-block'
							/>
						</span>
					</div>
					{/*
<div className='w-[80px] h-[80px] rounded-full bg-white-500 flex flex-col justify-center items-center transition-all border group-hover:scale-125 group-hover:drop-shadow-lg'>
						<strong className='text-4xl text-green-500'>{coursesNumber}</strong>
						<span className='text-sm'>
							{Number(coursesNumber) === 1
								? 'Kurs'
								: Number(coursesNumber) >= 5
								? 'Kurseva'
								: 'Kursa'}
						</span>
					</div>
*/}
				</div>
			</Link>
		</article>
	);
};
CourseCard.propTypes = {
	courseGroup: PropTypes.string,
	coursesNumber: PropTypes.string,
	url: PropTypes.string,
	bcgImage: PropTypes.string,
	bcgAlt: PropTypes.string,
};

export default CourseCard;
