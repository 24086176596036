import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Decoration from '../images/decoration-1.svg';

const CategoryCard = ({ courseName, url, price }) => {
	return (
		<article className='w-full md:w-6/12 xl:w-4/12 px-0 py-4 md:px-4'>
			<Link
				to={url}
				className='group relative flex min-h-[200px] p-5 overflow-hidden rounded-lg bg-white-500'
			>
				<div className='flex justify-between items-center relative z-10 min-h-[160px] w-full'>
					<div className='flex flex-col items-start flex-1 justify-between h-full'>
						<h3 className='text-sm lg:text-xl font-bold'>{courseName}</h3>
						<span className='relative px-5 pb-3 text-sm group-hover:text-green-300 transition'>
							Pogledajte više
							<img
								src={Decoration}
								alt='Dekoracija'
								className={`hidden group-hover:inline-block absolute transition-all left-0 w-full bottom-2 animate-bounce`}
							/>
						</span>
					</div>
					<div className='w-[120px] h-[120px] rounded-full bg-green-500 flex flex-col justify-center items-center transition-all border scale-100 group-hover:scale-125 group-hover:drop-shadow-lg mr-2  text-white-200'>
						<span className='text-sm'>Cijena već od:</span>
						<span className='text-sm'>
							<strong>{price}</strong> KM
						</span>
					</div>
				</div>
			</Link>
		</article>
	);
};
CategoryCard.propTypes = {
	courseName: PropTypes.string,
	url: PropTypes.string,
	price: PropTypes.number,
};

export default CategoryCard;
