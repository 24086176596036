import React from 'react';
import PropTypes from 'prop-types';

const Faq = ({ onclick, title, isActive, answer }) => {
	return (
		<div className='rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white-500'>
			<h2 className='mb-0'>
				<button
					className='group relative flex w-full items-center rounded-none border-0 bg-green-600 px-5 py-4 text-left text-md lg:text-base text-white-200 transition'
					type='button'
					onClick={onclick}
				>
					{title}
					<span className='-mr-1 ml-auto h-5 w-5 shrink-0'>
						{!isActive ? (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M12 4.5v15m7.5-7.5h-15'
								/>
							</svg>
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M19.5 12h-15'
								/>
							</svg>
						)}
					</span>
				</button>
			</h2>
			<div className={`${isActive ? 'block' : 'hidden'} border-0`}>
				<div className='px-5 py-4'>
					{<div dangerouslySetInnerHTML={{ __html: answer }} />}
				</div>
			</div>
		</div>
	);
};
Faq.propTypes = {
	onclick: PropTypes.func,
	title: PropTypes.string,
	answer: PropTypes.string,
	id: PropTypes.number,
};

export default Faq;
