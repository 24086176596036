import React, { useEffect } from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Courses from './data/courses.json';

const LinksCourse = ({ state }) => {
	return state?.map((item) => (
		<Link
			key={item.id}
			to={`kursevi/${item.category}/${item.course_slug}`}
			className='hidden'
		>
			{item.title}
		</Link>
	));
};
const LinksCategory = ({ state }) => {
	const categoryUrls = state?.map((item) => item.category);
	const unic = Array.from(new Set(categoryUrls));
	return unic.map((item, key) => (
		<Link key={key} to={`kursevi/${item}`} className='hidden'>
			Link
		</Link>
	));
};

const Layout = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			<Navbar />
			<LinksCourse state={Courses} />
			<LinksCategory state={Courses} />
			<Outlet />
			<Footer />
		</>
	);
};

export default Layout;
