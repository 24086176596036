import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Faq from '../components/Faq';
import Button from '../components/Button';
import CourseRegistrationForm from '../components/CourseRegistrationForm';
import ButtonLink from '../components/ButtonLink';
import Geek from '../images/icons/geek-solution-nerd-pokemon-go-logo-geek-logo-text-computer-logo.png';
import Loader from '../images/green-loader.gif';

import Courses from '../data/courses.json';
import Teachers from '../data/treiner.json';

const Course = () => {
	const { kategorija } = useParams();
	const { id } = useParams();
	const [course, setCourse] = useState(null);
	const [teacher, setTeacher] = useState(null);

	const [faqState, setFaqState] = useState();
	const [isFormActive, setIsFormActive] = useState(false);
	const [education, setEducation] = useState('');
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState('');
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		const courseData = Courses.find((item) => item.course_slug === id);
		setCourse(() => courseData);
	}, [id]);

	useEffect(() => {
		if (course) {
			const teacherData = Teachers.find(
				(item) => course && item.id === course.treiner_id
			);
			setTeacher(teacherData);
			setLoading(false);
		}
	}, [course]);

	useEffect(() => {
		setFaqState(course?.faq);
	}, [course?.faq]);

	useEffect(() => {
		setMounted(true);
		return () => {
			setMounted(false);
		};
	}, []);

	const courseEl = useMemo(() => {
		const faqButtonClickHandler = (index) => {
			const newArray = faqState.map((item, i) => {
				if (index === i + 1) {
					return { ...item, isActive: !item.isActive };
				} else {
					return { ...item, isActive: false };
				}
			});
			setFaqState(newArray);
		};

		return (
			<>
				<div className='w-full lg:w-8/12'>
					<div className='title mb-8'>
						<h1 className='text-3xl lg:text-4xl mb-3'>
							{course && course.title}
						</h1>
						<p className='mb-3'>
							Predavač:{' '}
							<span className='text-green-500 text-md font-bold'>
								{teacher && teacher.name}
							</span>
						</p>
						<p
							dangerouslySetInnerHTML={{
								__html: course && course.desription_one,
							}}
						/>
					</div>

					<div className='flex flex-col w-full lg:hidden mb-4'>
						<h2 className='text-2xl lg:text-3xl mb-3'>Prijavite se</h2>
						<div className='rounded-lg flex flex-col gap-y-3'>
							<article className='rounded-lg p-5 border border-t-0 border-b-1 rounded-b-lg text-white-200 bg-neutral-500'>
								<h3 className='text-lg mb-3'>GRUPNA EDUKACIJA</h3>
								<div className='p-4 border mb-4 flex flex-col items-center rounded-md'>
									<span className='text-2xl text-orange-500'>
										{course && course.price_group} KM
									</span>
									<span>Cijena kursa</span>
								</div>
								<div className='p-4 border flex flex-col items-center rounded-md mb-4'>
									<span className='text-2xl'>
										{course && course.price_group_rate} KM
									</span>
									<span>Cijena kursa na rate</span>
								</div>
								<div className='p-4 border flex flex-col items-center rounded-md'>
									<p className='text-2xl'>
										{course && course.houers_group}{' '}
										<span>{course && course.houer_or_class}</span>
									</p>
									<span>Trajanje kursa</span>
								</div>
								<ul className='list-inside list-disc flex flex-col mt-3'>
									<li>2 - 3 puta sedmično</li>
									<li>4-6 polaznika</li>
									<li>obezbjeđena literatura</li>
									<li>podrška predavača</li>
									<li>potvrda o završenoj edukaciji</li>
								</ul>
								<div className='flex justify-center'>
									<Button
										text='Prijavi se'
										onclick={() => {
											setIsFormActive(!isFormActive);
											setEducation('GRUPNA EDUKACIJA');
										}}
									/>
								</div>
							</article>
							<article className='rounded-lg p-2 pb-3 text-white-200  bg-neutral-500'>
								<h3 className='text-lg mb-3'>EDUKACIJA 1 NA 1</h3>
								<div className='p-4 border mb-4 flex flex-col items-center rounded-md'>
									<span className='text-2xl text-orange-500'>
										{course && course.price_individual} KM
									</span>
									<span>Cijena kursa</span>
								</div>
								<div className='p-4 border flex flex-col items-center rounded-md mb-4'>
									<span className='text-2xl'>
										{course && course.price_individual_rate} KM
									</span>
									<span>Cijena kursa na rate</span>
								</div>
								<div className='p-4 border flex flex-col items-center rounded-md'>
									<p className='text-2xl'>
										{course && course.houers_individual}{' '}
										<span>{course && course.houer_or_class}</span>
									</p>
									<span>Trajanje kursa</span>
								</div>
								<ul className='list-inside list-disc flex flex-col mt-3'>
									<li>2 - 3 puta sedmično</li>
									<li>predavač - 1 polaznik</li>
									<li>obezbjeđena literatura</li>
									<li>podrška predavača</li>
									<li>potvrda o završenoj edukaciji</li>
								</ul>
								<div className='flex justify-center'>
									<Button
										text='Prijavi se'
										onclick={() => {
											setIsFormActive(!isFormActive);
											setEducation('EDUKACIJA 1 NA 1');
										}}
									/>
								</div>
							</article>
						</div>
					</div>

					<div className='what mb-4'>
						<h2 className='text-2xl lg:text-3xl mb-3'>
							Na kursu ćete naučiti:
						</h2>
						{course && course.desription_two && (
							<p
								dangerouslySetInnerHTML={{
									__html: course && course.desription_two,
								}}
							/>
						)}
						{course && course.desription_three && (
							<p
								dangerouslySetInnerHTML={{
									__html: course && course.desription_three,
								}}
							/>
						)}
					</div>
					<div className='mb-8'>
						<h2 className='text-2xl lg:text-3xl mb-3'>Teme</h2>
						<ul className='list-disc list-inside'>
							{course &&
								course.skills.map((item, key) => <li key={key}>{item}</li>)}
						</ul>
						<div className='rounded-lg p-2 pb-3'>
							<ButtonLink
								url={course && course.pdf}
								text='Detaljan plan rada'
								isPdf={true}
							/>
						</div>
					</div>
					<div className='faq mb-12'>
						<h2 className='text-2xl lg:text-3xl mb-3'>
							FAQ - Često postavljena pitanja
						</h2>
						{faqState &&
							faqState.map((item) => (
								<Faq
									key={item.id}
									title={item.title}
									answer={item.answer}
									isActive={item.isActive}
									onclick={() => faqButtonClickHandler(item.id)}
								/>
							))}
					</div>
					<div className='author'>
						<h2 className='text-2xl lg:text-3xl mb-3'>O predavaču</h2>
						{teacher && (
							<div className='flex items-center gap-x-4 mb-8'>
								{teacher.image && (
									<img
										src={teacher.image}
										alt={teacher.name}
										className='rounded-full max-w-[60px]'
									/>
								)}
								<h3>{teacher?.name}</h3>
							</div>
						)}
						<div className='about'>
							<p>{teacher?.about}</p>
						</div>
					</div>
				</div>
				<div className='hidden lg:flex lg:flex-col w-4/12'>
					<h2 className='text-2xl lg:text-3xl mb-3'>Cijene kursa</h2>
					<div className='rounded-lg flex flex-col gap-y-3'>
						<article className='rounded-lg p-5 border border-t-0 border-b-1 rounded-b-lg text-white-200  bg-neutral-500'>
							<h3 className='text-lg mb-3 font-bold'>GRUPNA EDUKACIJA</h3>
							<div className='p-4 border mb-4 flex flex-col items-center rounded-md'>
								<span className='text-2xl text-orange-500'>
									{course && course.price_group} KM
								</span>
								<span>Cijena kursa</span>
							</div>
							<div className='p-4 border flex flex-col items-center rounded-md mb-4'>
								<span className='text-2xl'>
									{course && course.price_group_rate} KM
								</span>
								<span>Cijena kursa na rate</span>
							</div>
							<div className='p-4 border flex flex-col items-center rounded-md'>
								<p className='text-2xl'>
									{course && course.houers_group}{' '}
									<span>{course && course.houer_or_class}</span>
								</p>
								<span>Trajanje kursa</span>
							</div>
							<ul className='list-inside list-disc flex flex-col mt-3'>
								<li>2 - 3 puta sedmično</li>
								<li>4-6 polaznika</li>
								<li>obezbjeđena literatura</li>
								<li>podrška predavača</li>
								<li>potvrda o završenoj edukaciji</li>
							</ul>
							<div className='flex justify-center'>
								<Button
									text='Prijavi se'
									onclick={() => {
										setIsFormActive(!isFormActive);
										setEducation('GRUPNA EDUKACIJA');
									}}
								/>
							</div>
						</article>
						<article className='rounded-lg p-5 text-white-200 border border-t-0 border-x-0 border-b-1  bg-neutral-500'>
							<h3 className='text-lg mb-3 font-bold'>EDUKACIJA 1 NA 1</h3>
							<div className='p-4 border mb-4 flex flex-col items-center rounded-md'>
								<span className='text-2xl text-orange-500'>
									{course && course.price_individual} KM
								</span>
								<span>Cijena kursa</span>
							</div>
							<div className='p-4 border flex flex-col items-center rounded-md mb-4'>
								<span className='text-2xl'>
									{course && course.price_individual_rate} KM
								</span>
								<span>Cijena kursa na rate</span>
							</div>
							<div className='p-4 border flex flex-col items-center rounded-md'>
								<p className='text-2xl'>
									{course && course.houers_individual}{' '}
									<span>{course && course.houer_or_class}</span>
								</p>
								<span>Trajanje kursa</span>
							</div>
							<ul className='list-inside list-disc flex flex-col mt-3'>
								<li>2 - 3 puta sedmično</li>
								<li>predavač - 1 polaznik</li>
								<li>obezbjeđena literatura</li>
								<li>podrška predavača</li>
								<li>potvrda o završenoj edukaciji</li>
							</ul>
							<div className='flex justify-center'>
								<Button
									text='Prijavi se'
									onclick={() => {
										setIsFormActive(!isFormActive);
										setEducation('EDUKACIJA 1 NA 1');
									}}
								/>
							</div>
						</article>
						{data && <p className='text-lg'>{data}</p>}
					</div>
				</div>
			</>
		);
	}, [isFormActive, faqState, course, teacher, data]);
	if (!mounted) {
		return;
	}
	if (loading) {
		return (
			<div className='flex justify-center items-center'>
				<img src={Loader} alt='Green Loader' className='max-w-[250px]' />
			</div>
		);
	}

	return (
		<>
			<Helmet>
				<title>{course && course.title} - Geek Solutions</title>
				<link
					rel='canonical'
					href={`https://www.informaticki-kursevi.ba/kursevi/${kategorija}/${id}`}
				/>
				<link
					rel='alternate'
					href={`https://www.informaticki-kursevi.ba/kursevi/${kategorija}/${id}`}
					hreflang='sr-BA'
				/>
				<meta name='description' content={course && course.meta_description} />

				<meta property='og:type' content='website' />
				<meta
					property='og:title'
					content={`${course && course.title} - Geek Solutions`}
				/>
				<meta
					property='og:description'
					content={course && course.meta_description}
				/>
				<meta property='og:image' content={Geek} />
			</Helmet>
			<div className='container mx-auto px-5 py-5'>
				<nav className='w-full rounded-md'>
					<ol className='list-reset flex flex-wrap'>
						<li>
							<Link
								to='/kursevi'
								className='tex-sm text-blue-600 transition duration-150 ease-in-out hover:text-green-600 focus:text-primary-600 active:text-primary-700 uppercase font-bold'
							>
								Kursevi
							</Link>
						</li>
						<li>
							<span className='text-sm mx-1 text-neutral-500 dark:text-neutral-400 font-bold'>
								/
							</span>
						</li>
						<li>
							<Link
								to={`/kursevi/${kategorija}`}
								className='text-sm text-blue-600 transition duration-150 ease-in-out hover:text-green-600 focus:text-primary-600 active:text-green-700 uppercase font-bold'
							>
								{kategorija.toLocaleUpperCase()}
							</Link>
						</li>
						<li>
							<span className='text-sm mx-1 text-neutral-500 dark:text-neutral-400 font-bold'>
								/
							</span>
						</li>
						<li className='text-neutral-500 dark:text-neutral-400 font-bold'>
							{id.toUpperCase()}
						</li>
					</ol>
				</nav>
			</div>
			<div className='container mx-auto px-5 pb-16'>
				<div className='flex gap-x-5'>
					<Suspense fallback={null}>{courseEl}</Suspense>
				</div>

				{isFormActive && (
					<CourseRegistrationForm
						groupValue={education}
						courseValue={id}
						setIsActive={setIsFormActive}
						setData={setData}
					/>
				)}
			</div>
		</>
	);
};

export default Course;
