import React from 'react';
import PropTypes from 'prop-types';
import Bcg from '../images/bg-1.svg';

const Button = ({ text, onclick, type }) => {
	return (
		<button
			type={type}
			className='w-[220px] h-[70px] flex relative justify-center items-center mt-5 text-white-100 transition-colors hover:text-blue-700'
			onClick={onclick}
		>
			<img
				src={Bcg}
				alt='Pozadina'
				className='absolute top-0 left-0 w-[220px] h-[70px]'
			/>
			<span className='mt-2 relative z-10'>{text}</span>
		</button>
	);
};
Button.propTypes = {
	onclick: PropTypes.func,
	text: PropTypes.string,
	type: PropTypes.string,
};
export default Button;
