import React from 'react';
import Phase from './Phase';

import FirstStep from '../images/what-todo/1-min.webp';
import SecStep from '../images/what-todo/2-min.webp';
import ThirthStep from '../images/what-todo/3-min.webp';
import FourthStep from '../images/what-todo/4-min.webp';
//import FifthStep from '../images/what-todo/5-min.webp';

const HomePases = () => {
	return (
		<div className='container mx-auto px-5 2xl:px-0 overflow-x-hidden pt-12'>
			<div className='text-center mb-8'>
				<h2 className='text-3xl lg:text-4xl mb-5'>
					U četiri koraka do sertifikata
				</h2>
				<p className='max-w-[760px] mx-auto'>
					Iako je za dobijanje naših sertifikata neophodno samo nekoliko koraka,{' '}
					<strong className='text-green-500'>sertifikacija</strong> nije kraj,
					već samo početak da nastavite sa profesionalnim razvojem, učite nove
					tehnologije i trendove u IT-u, te razvijajte svoje vještine kako biste
					ostali konkurentni na tržištu rada. Sertifikati su odličan način da
					unaprijedite svoju karijeru, dopunite svoj CV i pokažete svoje
					vještine potencijalnim poslodavcima.
				</p>
			</div>
			<Phase
				number='01'
				title='Izaberite kurs i kontaktirajte nas'
				textone='Pregledajte našu ponudu kurseva iz širokog spektra koji pokrivaju različite oblasti IT-a i odaberite onaj koji najbolje odgovara vašim interesovanjima i ciljevima, a zatim nas kontaktirajte načinom koji Vama najviše odgovara.'
				src={FirstStep}
				alt='Alt za prvu sliku'
				speedOne='-100px'
				speedTwo='100px'
				order='order-none'
			/>
			<Phase
				number='02'
				title='Detaljna uputstva'
				textone='Naša prijateljska administracija će Vam odgovoriti u roku od 24h te vam pružiti sve neophodne informacije o samom kursu načinu rada i načinu za prijavu. Bilo da se prijavljujete lično ili putem naše web stranice, obezbjedićemo vam jednostavan proces kako biste brzo i efikasno što prije krenuli u proces edukacije.'
				src={SecStep}
				alt='Alt za drugu sliku'
				speedOne='100px'
				speedTwo='-100px'
				order='order-last'
			/>
			<Phase
				number='03'
				title='Edukacija'
				textone='Vaše obrazovanje počinje prisustvom na našim časovima. Naši stručni predavači će vas voditi kroz sve relevantne teme i oblasti. Bilo da ste početnik ili napredni korisnik, naše obuke su prilagođene svim nivoima znanja. Naš cilj je da vam pružimo praktično znanje koje ćete moći da primjenite u realnom radnom okruženju. Naša interaktivna okruženja za učenje pružaju vam mogućnost da eksperimentišete sa tehnologijom i razvijate praktične vještine.'
				src={ThirthStep}
				alt='Alt za trecu sliku'
				speedOne='-100px'
				speedTwo='100px'
				order='order-none'
			/>
			<Phase
				number='04'
				title='Sertifikat'
				textone='Nakon uspješno završenog kursa, dobićete zasluženi sertifikat. To je ne samo potvrda vašeg znanja i vještina, već i vrijedna referenca koja će vam pomoći u vašoj karijeri, koju ćete dodati u svoj CV. Sertifikat je vrijedna potvrda za vaš profesionalni razvoj i može vam pomoći pri zapošljavanju ili napredovanju u karijeri.'
				src={FourthStep}
				alt='Alt za cetvrtu sliku'
				speedOne='100px'
				speedTwo='-100px'
				order='order-last'
			/>
		</div>
	);
};

export default HomePases;
