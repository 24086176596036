import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import Geek from '../images/icons/geek-solution-nerd-pokemon-go-logo-geek-logo-text-computer-logo.png';
import ContactHeader from '../components/ContactHeader';

const RegisterSchema = Yup.object().shape({
	name: Yup.string().required('Obavezno polje'),
	email: Yup.string().email('Email nije validan').required('Obavezno polje'),
	phone: Yup.string().required('Obavezno polje'),
});
const initialFormValues = {
	name: '',
	email: '',
	phone: '',
	message: '',
};
const Contact = () => {
	const [data, setData] = useState('');

	return (
		<>
			<Helmet>
				<title>Kontakt - Geek Solutions</title>
				<link
					rel='canonical'
					href={`https://www.informaticki-kursevi.ba/kontakt`}
				/>
				<link
					rel='alternate'
					href={`https://www.informaticki-kursevi.ba/kontakt`}
					hreflang='sr-BA'
				/>
				<meta
					name='description'
					content='Kontakt sa nama možete ostvari telefonom (+387 660 150), email-om (informatickionlinekursevi@gmail.com). Vaši upiti su nam važni!'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content='Kontakt - Geek Solutions' />
				<meta
					property='og:description'
					content='Kontakt sa nama možete ostvari telefonom (+387 660 150), email-om (informatickionlinekursevi@gmail.com). Vaši upiti su nam važni!'
				/>
				<meta property='og:image' content={Geek} />
			</Helmet>
			<ContactHeader />
			<div className='container pb-24 px-6 mx-auto'>
				<section className='mb-32 text-gray-900'>
					<div className='flex flex-wrap'>
						<div className='grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full lg:w-6/12 px-3 lg:px-6'>
							<h2 className='text-2xl mb-6'>Geek Solutions</h2>
							<p className='text-gray-700 mb-6'>
								Geek Solutions je centar za edukaciju i usavršavanje iz koga
								svaki pojedinac izlazi bogatiji za novo saznanje i iskustvo koje
								će unaprijediti njegovu karijeru i donijeti napredak njegovoj
								kompaniji
							</p>

							<p className='text-gray-700 mb-2'>+387 66 660 150</p>
							<p className='text-gray-700 mb-2'>
								<a
									href='mailto: informatickionlinekursevi@gmail.com'
									className='text-indigo-500 hover:text-green-500'
								>
									informatickionlinekursevi@gmail.com
								</a>
							</p>
							<p className='text-gray-700'>Bosna i Hercegovina</p>
						</div>
						<div className='grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full lg:w-6/12 px-3 lg:px-6'>
							<Formik
								initialValues={initialFormValues}
								validationSchema={RegisterSchema}
								onSubmit={async (values, { setSubmitting, resetForm }) => {
									try {
										const formdata = new FormData();
										formdata.append('name', values.name);
										formdata.append('email', values.email);
										formdata.append('phone', values.phone);
										formdata.append('message', values.message);

										const res = await axios.post('/api/contact.php', formdata);
										const data = await res.data;
										setData(data);
										setSubmitting(false);
										resetForm(initialFormValues);
										setTimeout(() => {
											setData('');
										}, [3000]);
									} catch (err) {
										console.log(err);
									}
								}}
							>
								{({ values, handleChange, handleBlur, isSubmitting }) => (
									<Form>
										<div className='form-group mb-6'>
											<Field
												type='text'
												name='name'
												value={values.name}
												onChange={handleChange}
												onBlur={handleBlur}
												className='form-control block
												w-full
												px-3
												py-1.5
												text-base
												font-normal
												text-gray-700
												bg-white bg-clip-padding
												border border-solid border-gray-300
												rounded
												transition
												ease-in-out
												m-0
												focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
												placeholder='Ime i prezime'
											/>
											<ErrorMessage
												name='name'
												render={(msg) => (
													<div className='text-red-600'>{msg}</div>
												)}
											/>
										</div>
										<div className='form-group mb-6'>
											<Field
												type='email'
												name='email'
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
												className='form-control block
												w-full
												px-3
												py-1.5
												text-base
												font-normal
												text-gray-700
												bg-white bg-clip-padding
												border border-solid border-gray-300
												rounded
												transition
												ease-in-out
												m-0
												focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
												id='exampleInput8'
												placeholder='Email adresa'
											/>
											<ErrorMessage
												name='email'
												render={(msg) => (
													<div className='text-red-600'>{msg}</div>
												)}
											/>
										</div>
										<div className='form-group mb-6'>
											<Field
												type='tel'
												name='phone'
												value={values.phone}
												onChange={handleChange}
												onBlur={handleBlur}
												className='form-control block
												w-full
												px-3
												py-1.5
												text-base
												font-normal
												text-gray-700
												bg-white bg-clip-padding
												border border-solid border-gray-300
												rounded
												transition
												ease-in-out
												m-0
												focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
												placeholder='Telefon'
											/>
											<ErrorMessage
												name='phone'
												render={(msg) => (
													<div className='text-red-600'>{msg}</div>
												)}
											/>
										</div>
										<div className='form-group mb-6'>
											<Field
												name='message'
												as='textarea'
												value={values.message}
												onChange={handleChange}
												onBlur={handleBlur}
												className='
													form-control
													block
													w-full
													px-3
													py-1.5
													text-base
													font-normal
													text-gray-700
													bg-white bg-clip-padding
													border border-solid border-gray-300
													rounded
													transition
													ease-in-out
													m-0
													focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
												'
												rows='3'
												placeholder='Poruka'
											/>
										</div>
										{data && <p className='text-lg'>{data}</p>}
										<button
											type='submit'
											className='
										w-full
										px-6
										py-2.5
										bg-green-600
										text-white-100
										font-medium
										text-xs
										leading-tight
										uppercase
										rounded
										shadow-md
										hover:bg-blue-700 hover:shadow-lg
										focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
										active:bg-blue-800 active:shadow-lg
										transition
										duration-150
										ease-in-out'
										>
											Pošalji
										</button>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default Contact;
