import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../images/logo.png';
import Phone from '../images/phone.png';
import Viber from '../images/viber.png';
import Whatsapp from '../images/whatsapp.png';

const navigation = [
  { name: 'Naslovna', href: '/' },
  { name: 'Kursevi', href: 'kursevi' },
  { name: 'Kontakt', href: 'kontakt' },
  { name: 'Sertifikacija', href: 'testiranje-i-sertifikacija' },
];
const activeStyle = {
  color: '#39b54a',
};
export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrollded] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    const fromTop = window.scrollY || window.pageYOffset;
    if (fromTop > 64) {
      setIsScrollded(true);
      setIsFixed(true);
    } else {
      setIsScrollded(false);
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <header className='inset-x-0 relative z-50'>
      <div className='container mx-auto px-5'>
        <div className='flex justify-between items-center py-2'>
          <div className='flex justify-start items-center'>
            <p>
              <a
                href='tel:+38766660150'
                className='text-blue-800 hover:text-green-600 transition'
              >
                +387 66 660 150
              </a>
            </p>
          </div>
          <div className='flex justify-end items-center gap-x-2'>
            <a href='tel:+38766660150' className='w-[48px]'>
              <img src={Phone} alt='Phone' />
            </a>
            <a
              href='viber://chat?number=%2B38766660150'
              className='w-[48px] p-1'
            >
              <img src={Viber} alt='Viber' />
            </a>
            <a href='https://wa.me/38766660150' className='w-[48px] p-1'>
              <img src={Whatsapp} alt='Whatsapp' />
            </a>
          </div>
        </div>
        <hr />
      </div>
      <div
        className={`${
          isFixed ? 'fixed left-0 top-0 w-full z-50' : 'relative'
        } ${isScrolled ? 'bg-white-500' : ''}`}
      >
        <div className='container mx-auto px-5'>
          <nav
            className='flex items-center justify-between'
            aria-label='Global'
          >
            <div className='flex'>
              <Link to='/'>
                <span className='sr-only'>Geek Solutions</span>
                <img
                  className={`${
                    isScrolled ? 'h-14 lg:h-20' : 'h-20 lg:h-32'
                  } transition-all duration-300`}
                  w-auto
                  src={Logo}
                  alt='Logo'
                />
              </Link>
            </div>
            <div className='flex lg:hidden'>
              <button
                type='button'
                className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className='sr-only'>Open main menu</span>
                <Bars3Icon className='h-8 w-8' aria-hidden='true' />
              </button>
            </div>
            <div className='hidden lg:flex lg:gap-x-12'>
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className='text-md font-semibold leading-6 text-blue-900 hover:text-green-500'
                  style={({ isActive }) => (isActive ? activeStyle : null)}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </nav>
        </div>
      </div>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-50' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white-500 px-5 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <Link to='/'>
              <span className='sr-only'>Your Company</span>
              <img className='h-20 w-auto' src={Logo} alt='Logo' />
            </Link>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-8 w-8' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:underline'
                    style={({ isActive }) => (isActive ? activeStyle : null)}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
