import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ParallaxBanner } from 'react-scroll-parallax';

import Cert from '../images/certicifate.webp';
import Geek from '../images/icons/geek-solution-nerd-pokemon-go-logo-geek-logo-text-computer-logo.png';

const Certification = () => {
	return (
		<>
			<Helmet>
				<title>Testiranje i sertifikacija - Geek Solutions</title>
				<link
					rel='canonical'
					href={`https://www.informaticki-kursevi.ba/testiranje-i-sertifikacija`}
				/>
				<link
					rel='alternate'
					href={`https://www.informaticki-kursevi.ba/testiranje-i-sertifikacija`}
					hreflang='sr-BA'
				/>
				<meta
					name='description'
					content='Sertifikovanim znanjem - ostvarite prednost pri zaposlenju u odnosu na svoju konkurenciju. Geek Solutions je tu da Vam pomogne i ponudi besplatno testiranje kako biste utvrdili stepen Vašeg znanja.'
				/>

				<meta property='og:type' content='website' />
				<meta
					property='og:title'
					content='Testiranje i sertifikacija - Geek Solutions'
				/>
				<meta
					property='og:description'
					content='Sertifikovanim znanjem - ostvarite prednost pri zaposlenju u odnosu na svoju konkurenciju. Geek Solutions je tu da Vam pomogne i ponudi besplatno testiranje kako biste utvrdili stepen Vašeg znanja.'
				/>
				<meta property='og:image' content={Geek} />
			</Helmet>
			<div className='container mx-auto px-5 pb-8'>
				<div className='title my-16 text-center'>
					<h1 className='text-3xl lg:text-4xl mb-3'>
						Testiranje i sertifikacija
					</h1>
					<p className='max-w-[760px] mx-auto'>
						Zbog konkursa za posao potrebna Vam je{' '}
						<strong className='text-green-500 text-[20px]'>
							potvrda o poznavanju rada na računaru
						</strong>
						?<br /> Geek Solutions je tu da Vam pomogne i ponudi besplatno
						testiranje kako biste utvrdili stepen Vašeg znanja.
					</p>
				</div>
				<div className='flex gap-x-8 flex-wrap'>
					<div className='w-full lg:w-6/12'>
						<div className='mb-4'>
							<h2 className='text-2xl lg:text-3xl mb-4'>
								Testiranje i sertifikacija
							</h2>
							<p>
								Jedan od uslova koji morate da ispunite prilikom apliciranja za
								određeni posao u većini djelatnosti je da posjedujete sertifikat
								o poznavanju rada na računaru. Većina vas posjeduje neophodno
								znanje, ali nema validan sertifikat kojim to može i da dokaže.
								Ukoliko vam je za zaposlenje potreban sertifikat iz osnovne
								računarske pismenosti, organizujemo individualna testiranja.
							</p>
						</div>
						<div className='mb-4'>
							<h2 className='text-2xl lg:text-3xl mb-4'>
								Kako dogovoriti termine polaganje?
							</h2>
							<p>
								Termin testiranja dogovaramo prema Vašem slobodnom vremenu, a
								nudimo Vam mogućnost i <strong>ONLINE</strong> testiranja i
								konsultacija na brojeve telefona:
							</p>
						</div>
						<div className='mb-4'>
							<h2 className='text-2xl lg:text-3xl mb-4'>
								Oblasti testiranja i sertifikacije:
							</h2>
							<ul className='list-disc list-inside'>
								<li>
									Osnove računarske pismenosti - Windows, MS Word, MS Excel
								</li>
								<li>Napredni kurs - MS Word, MS Excel, MS PowerPoint, Email</li>
							</ul>
						</div>
						<div className='mb-4'>
							<h2 className='text-2xl lg:text-3xl mb-4'>Cijene:</h2>
							<p className='mb-4'>
								Test se smatra položenim ukoliko je procenat uspješnih odgovora
								preko 60%. Ako je polaganje testa uspješno završeno, potvrdu
								ćete dobiti u roku od nekoliko dana na kućnu adresu.
							</p>
							<p>
								Testiranje je besplatno, ali izdavanje certifikata je{' '}
								<strong className='text-xl'>80,00 KM</strong>
							</p>
						</div>
					</div>
					<div className='w-full lg:flex-1 min-h-[580px] relative after:content-[""] after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-gray-900 after:opacity-20 rounded-3xl  overflow-hidden leading-[0]'>
						<ParallaxBanner
							layers={[{ image: Cert, speed: -20 }]}
							className='h-full'
						></ParallaxBanner>
					</div>
				</div>
			</div>
		</>
	);
};

export default Certification;
