import React from 'react';
import PropTypes from 'prop-types';

const WhyUsCard = ({ children, title, text }) => {
	return (
		<div className='bg-green-500 text-white-500 rounded-2xl p-5 text-center rounded-ss-none w-full min-w-[280px] flex-1'>
			<div className='icon text-7xl flex justify-center mb-4'>{children}</div>
			<h3 className='text-2xl mb-3'>{title}</h3>
			<div dangerouslySetInnerHTML={{ __html: text }} />
		</div>
	);
};
WhyUsCard.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	text: PropTypes.string,
};

export default WhyUsCard;
